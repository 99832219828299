import React, { useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import "./next-section-connector.scss";
import { SectionEntity } from "../../generated/graphql";
import { MstrButton } from "../mstr-button/mstr-button";
import { TypeText } from "../type-text/type-text";
import { StaggerList } from "../stagger-list/stagger-list";
import { RefdocItem } from "../refdoc-item/refdoc-item";
import { FadeIn } from "../fade-in/fade-in";
import { CONSTANTS } from "../../constants";

interface Props {
  currentSectionId: number;
  nextSectionId: number;
  navigateToNextSection: Function;
  refdocs: Object[];
  isPreview?: boolean;
}

const GET_SECTION_DETAILS = gql`
  query SectionDetailsForConnector($id: Int!) {
    section(id: $id) {
      id
      section_header
    }
  }
`;

const SEND_REFDOCS_MUTATION = gql`
  mutation SendRefdocEmail($section_id: Int!) {
    sendRefdocEmail(section_id: $section_id)
  }
`;

interface NextSectionData {
  section: SectionEntity;
}

const getGotoNextElementMessage = () => {
  const messages = [
    `Now let's move on and talk about`,
    `Alright, let’s move on to the next discussion point about`,
    `Let’s continue our discussion and talk about`,
    `Alright, let’s go deeper and discuss`,
    `I think this is a good time to discuss`,
  ]
  return messages[Math.floor(Math.random() * messages.length)];
}

export const NextSectionConnector = (props: Props) => {
  const [sendRefdocEmail] = useMutation(SEND_REFDOCS_MUTATION, {});

  useEffect(() => {
    if (!props.refdocs.length) {
      setRefdocsShown(true);
    }
  }, []);

  const [shouldShowRefDocs, setShouldShowRefDocs] = useState(false);
  const [refdocsShown, setRefdocsShown] = useState(false);
  const [nextSectionLoading, setNextSectionLoading] = useState(false);

  // const [showButtons, setShowButtons] = useState(false);
  // const [emailButtonStatus, setEmailButtonStatus] = useState(0); // 1. Send Email 2. No Thanks

  const { loading, error, data } = useQuery<NextSectionData>(
    GET_SECTION_DETAILS,
    {
      variables: { id: props.nextSectionId },
    }
  );
  // const history = useHistory();
  const [isConnorLanguageTyped, setConnectorLanguageTyped] = useState(false);

  const [nextSectionConnectorLanguage, setNextSectionConnectorLanguage] = useState(getGotoNextElementMessage());

  if (loading) return <div></div>;
  if (error) return <div>ERROR</div>;

  const documents = JSON.parse(JSON.stringify(props.refdocs));
  documents.sort((a: any, b: any) => a.sort_id - b.sort_id);

  return (
    <div style={{ paddingBottom: 20 }}>
      {props.refdocs.length > 0 && (
        <TypeText isPreview={props.isPreview} onComplete={() => setShouldShowRefDocs(true)}>
          Here are some interesting document(s) that will advance your knowledge
          of this concept. I will be emailing these to you too.
        </TypeText>
      )}
      <br />
      {shouldShowRefDocs && (
        <StaggerList
          onAnimationComplete={() => {
            setTimeout(() => setRefdocsShown(true), 1000);

            const token = localStorage.getItem("token");
            if (token) {
              const user_id = JSON.parse(atob(token.split(".")[1])).userId;
              const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  user_id,
                  section_id: props.currentSectionId,
                }),
              };
              sendRefdocEmail({
                variables: {
                  section_id: props.currentSectionId,
                },
              });
            }
          }}
          list={documents.map((doc: any) => (
            <div>
              <RefdocItem id={doc.id} name={doc.document_name} url={doc.url} type={doc.reftype} />
              <br />
            </div>
          ))}
        />
      )}

      {refdocsShown && (
        <div style={{ fontSize: "1.7em" }}>
          <TypeText isPreview={props.isPreview} onComplete={() => setConnectorLanguageTyped(true)}>
            {nextSectionConnectorLanguage}{" "}
            <b>{data?.section.section_header}</b>
          </TypeText>
        </div>
      )}

      <br />
      <br />
      {isConnorLanguageTyped && (
        <MstrButton showLoading={nextSectionLoading} disabled={nextSectionLoading || props.isPreview} onClick={() => {
          setNextSectionLoading(true);
          setTimeout(() => {
            props.navigateToNextSection();
          }, 1500);
        }} text="Let's go" />
      )}
      <br />
      <br />
      <br />
    </div>
  );
};
